<template>
  <div>
    <el-card shadow="never">
      <el-form label-position="right"
               :model="formData"
               ref="feedbackForm"
               label-width="105px"
               element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称：" prop="title">
              {{formData.name}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品类型：">
              {{formData.type | productType2Text}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-col :span="12">
            <el-form-item label="开放时间：" prop="title">
              {{formData.openTime}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品标签：" prop="type">
              {{formData.tag}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-col :span="12">
            <el-form-item label="商品价格" prop="publisher">
              {{formData.price}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品评分" prop="orderNo">
              {{formData.score}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-col :span="12">
            <el-form-item label="供应商">
              {{formData.supplierId}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品描述" prop="orderNo">
              {{formData.description}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-col :span="12">
            <el-form-item label="商品封面">
              <img class="img" @click="showImg(formData.cover, '商品封面')" :src="formData.cover" alt="">
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-button type="primary" @click="handleAddProductDetail">新增商品细节</el-button>
          <el-form-item label="商品细节">
            <el-table :data="productDetailList"
                      border
                      max-height="300px"
                      v-loading="loadingList"
                      row-key="id">
              <el-table-column type="index" label="序号" min-width="80"></el-table-column>
              <el-table-column label="商品细节">
                <template v-slot="{ row }">
                  <img :src="row.image" class="displayImg" alt="">
                </template>
              </el-table-column>
              <el-table-column
                  fixed="right"
                  label="操作">
                <template v-slot="{ row }">
                  <el-button type="text" @click="handleDeleteDetail(row)">删除</el-button>
                  <el-button type="text" @click="handleEditDetail(row)">编辑</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
                :current-page.sync="detailPageObj.currentPage"
                :total="detailPageObj.total"
                :page-sizes="detailPageObj.pageSizes"
                :page-size="detailPageObj.pageSize"
                @current-change="handlerDetailPageChange"
                @size-change="handlerDetailSizeChange"
                :layout="detailPageObj.layout"></el-pagination>
          </el-form-item>
        </el-row>
        <el-row class="areaSplit">
          <el-button type="primary" @click="handleAddProductUsage">新增商品过往剧集</el-button>
          <el-form-item label="商品过往剧集">
            <el-table :data="productUsage"
                      border
                      max-height="300px"
                      v-loading="loadingUsageList"
                      row-key="id">
              <el-table-column fixed prop="name" label="剧集名称" min-width="100">
<!--                <template v-slot="{ row }">-->
<!--                  <router-link :to="{name: 'productUsage', query: { id: row.id }}"><el-button type="text">{{row.name}}</el-button></router-link>-->
<!--                </template>-->
              </el-table-column>
              <el-table-column label="剧集封面">
                <template v-slot="{ row }">
                  <img :src="row.cover" class="displayImg" alt="">
                </template>
              </el-table-column>
              <el-table-column
                  fixed="right"
                  label="操作">
                <template v-slot="{ row }">
                  <el-button type="text" @click="handleDeleteUsage(row)">删除</el-button>
                  <el-button type="text" @click="handleEditUsage(row)">编辑</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
                :current-page.sync="usagePageObj.currentPage"
                :total="usagePageObj.total"
                :page-sizes="usagePageObj.pageSizes"
                :page-size="usagePageObj.pageSize"
                @current-change="handlerUsagePageChange"
                @size-change="handlerUsageSizeChange"
                :layout="usagePageObj.layout"></el-pagination>
          </el-form-item>
        </el-row>
        <el-row class="areaSplit">
          <el-button @click="backToList">返回列表</el-button>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {execute} from "./option"
import globalConfig from 'config/global'
export default {
  name: "productDetail",
  data() {
    return {
      id: null,
      formData: {},
      productDetailList: [],
      loadingList: false,
      detailPageObj: {
        ...globalConfig.pagination,
        currentPage: 1,
        total: 0
      },
      productUsage: [],
      loadingUsageList: false,
      usagePageObj: {
        ...globalConfig.pagination,
        currentPage: 1,
        total: 0
      }
    }
  },
  created() {
    this.id = this.$route.params.productId
    console.log(this.id)
    this.tryGetProductById()
    this.tryGetProductDetailList()
    this.tryGetProductUsageList()
  },
  methods: {
    ...mapActions(['getProductById', 'getProductDetailList', 'deleteProductDetail', 'getProductUsageList', 'deleteProductUsage']),
    tryGetProductById() {
      this.getProductById(this.id).then(res => {
        console.log(res)
        this.formData = res
      })
    },
    // 获取商品细节 列表
    tryGetProductDetailList() {
      this.getProductDetailList({
        page: this.detailPageObj.currentPage,
        size: this.detailPageObj.pageSize,
        productId: this.id
      }).then(res => {
        const { recordList, total } = res
        this.productDetailList = recordList || []
        this.detailPageObj.total = total
      })
    },
    // 获取商品推荐 列表
    tryGetProductUsageList() {
      this.getProductUsageList({
        page: this.usagePageObj.currentPage,
        size: this.usagePageObj.pageSize,
        productId: this.id,
        type: this.formData.type
      }).then(res => {
        const { recordList, total } = res
        this.productUsage = recordList || []
        this.usagePageObj.total = total
      })
    },
    // 新增商品 细节
    handleAddProductDetail() {
      execute('addOrEditProductDetail', {
        optType: 'add',
        detail: { ...this.formData, id: this.id },
        callback: this.tryGetProductDetailList
      })
    },
    // 新增商品 过往
    handleAddProductUsage() {
      execute('addOrEditProductUsage', {
        optType: 'add',
        detail: { id: this.id, supplierId: this.formData.supplierId },
        callback: this.tryGetProductUsageList
      })
    },
    // 删除 细节
    handleDeleteDetail(row) {
      this.$confirm('确认删除该商品细节吗？').then(() => {
        this.deleteProductDetail(row.id).then(() => {
          this.$message.success('操作成功')
        }).finally(() => {
          this.tryGetProductDetailList()
        })
      })
    },
    // 编辑 细节
    handleEditDetail(row) {
      execute('addOrEditProductDetail', {
        optType: 'edit',
        detail: { ...row },
        callback: this.tryGetProductDetailList
      })
    },
    // 细节分页
    handlerDetailPageChange(page) {
      this.detailPageObj.currentPage = page
      this.tryGetProductDetailList()
    },
    handlerDetailSizeChange(size) {
      this.detailPageObj.pageSize = size
      this.tryGetProductDetailList()
    },
    // 过往剧集分页
    handlerUsagePageChange(page) {
      this.usagePageObj.currentPage = page
      this.tryGetProductUsageList()
    },
    handlerUsageSizeChange(size) {
      this.usagePageObj.pageSize = size
      this.tryGetProductUsageList()
    },
    // 删除 过往剧集
    handleDeleteUsage(row) {
      this.$confirm('确认删除该商品细节吗？').then(() => {
        this.deleteProductUsage(row.id).then(() => {
          this.$message.success('操作成功')
        }).finally(() => {
          this.tryGetProductUsageList()
        })
      })
    },
    // 编辑 过往剧集
    handleEditUsage(row) {
      execute('addOrEditProductUsage', {
        optType: 'edit',
        detail: { ...row },
        callback: this.tryGetProductUsageList
      })
    },
    // 展示图片
    showImg(imgUrl, title) {
      execute('showImg', {
        imgUrl,
        title
      })
    },
    backToList() {
      this.Event.$emit('closeTab')
      this.$router.push({
        name: 'productList'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.areaSplit {
  border-top: 1px solid #909399;
  padding-top: 15px;
  max-height: 450px;
  .el-col{
    //width: 100%;
    height: 100%;
    .img{
      width: 100%;
      height: 100%;
      max-height: 400px;
      cursor: pointer;
    }
  }
}
.displayImg {
  display: block;
  max-width: 120px;
}
</style>
